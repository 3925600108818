import { useState } from "react";
import ProfileMenu from "../../../components/ProfileMenu";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAtom } from "jotai";
import {
    authAtom,
    balanceAtom,
    payDataAtom,
    payDataTimeAtom,
} from "../../../App";
import { toast } from "react-toastify";

export default function Deposit() {
  const [t, ] = useTranslation();
  const [balance, ] = useAtom(balanceAtom);
  const [depositSum, setDepositSum] = useState('');
    const [senderName, setSenderName] = useState("");
    const [, setPayDataTime] = useAtom(payDataTimeAtom);
    const [, setPayData] = useAtom(payDataAtom);
    const [depMethod, setDepMethod] = useState("card");

    const [auth] = useAtom(authAtom);
  const [bonuses,] = useState([
        {
            name: "100% на депозит от 5000 RUB",
        },
        {
            name: "100% на депозит от 50000 RUB",
        },
    ]);
    const [selectedBonus, setSelectedBonus] = useState(0);
  const [selectedCurrency, ] = useState("RUB");
    return (
        <>
            <div className="Profile-Block">
                <ProfileMenu url={"/profile/wallet/deposit"} />
                <div className="Profile-Block__container">
                    <div className="Mobile-Selector">
            <Link to="/profile/wallet/wallets">{t("your_wallets")}</Link>
                        <Link to="/profile/wallet/deposit" className="selected">
                            {t("deposit")}
                        </Link>

            <Link to="/profile/wallet/withdraw">{t("withdraw")}</Link>
                        <Link to="/profile/wallet/history">История</Link>
                    </div>
                    <span>
                        {" "}
                        <p>{t("wallet")}</p>{" "}
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.5 7.08329L4.58333 4.99996L2.5 2.91663M5.41667 7.08329L7.5 4.99996L5.41667 2.91663"
                                stroke="white"
                                stroke-opacity="0.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <p>{t("deposit")}</p>
                    </span>
                    <h1>{t("wallet")}</h1>
                    <div className="Profile-Block__fields">
                        <div>
                            <nav>
                                <p>{t("refill_wallet")}</p>
                                <div className="selector white-selector  ">
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                      e.target.parentElement.classList.toggle("open");
                                        }}
                                        className="selector__header"
                                    >
                                        <p className="selector__value">
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                        <circle cx="8" cy="8" r="8" fill="#CC3332" />
                                                <path
                                                    d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z"
                                                    fill="white"
                                                />
                                            </svg>

                      {balance.RUB.toFixed(2).toLocaleString("en-US")}
                                        </p>
                                        <svg
                                            width="17"
                                            height="17"
                                            viewBox="0 0 17 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M4.25 6.375L8.5 10.625L12.75 6.375"
                                                stroke="white"
                                                strokeWidth="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>

                                    <div
                                        onClick={(e) => {
                                            if (e.target.nodeName == "A") {
                                                e.target.parentElement.parentElement.parentElement.classList.remove(
                          "open"
                                                );
                                                return;
                                            }
                      if (e.target.classList.contains("selector__body")) return;
                                            e.target.parentElement.parentElement.classList.remove(
                        "open"
                                            );

                                            if (!e.target.nextSibling) return;
                                            e.target.parentElement.previousSibling.querySelector(
                        "p"
                                            ).innerHTML = e.target.innerHTML;
                                        }}
                                        className="selector__body"
                                    >
                                        <div>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#CC3332"/><path d="M5.97 12V4.86h1.88q1.31 0 1.94.53.64.53.64 1.54 0 .68-.31 1.17-.31.48-.92.74-.6.26-1.49.26h-.84V12zm-.87-1.36v-.65h3.42v.65zm0-1.54v-.76h2.29v.76zm2.48-.76q.61 0 1.03-.13.43-.13.66-.43t.23-.82q0-.68-.42-1.01t-1.31-.33h-.9v2.72z" fill="#fff"/></svg>
                      {balance.RUB.toFixed(2).toLocaleString("en-US")}
                                        </div>
                                    </div>
                                </div>
                                <p>{t("deposit_method")}</p>
                                <div className="left-right-select y">
                                    <div
                                        onClick={(e) => {
                                            e.target.parentElement
                                                .querySelector(".selected")
                                                .classList.remove("selected");
                                            e.target.classList.add("selected");
                                            setDepMethod("card");
                                        }}
                                        className="selected"
                                    >
                                        <div>
                      <img src="/images/sber.png" alt="" />
                                            <svg
                                                id="logosandtypes_com"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 150 150"
                                            >
                                                <style
                                                    dangerouslySetInnerHTML={{
                                                        __html: ".st1{fill:#ee3424}",
                                                    }}
                                                />
                                                <g id="Layer_2">
                                                    <path
                                                        id="Layer_3"
                                                        d="M0 0h150v150H0V0z"
                                                        fill="none"
                                                    />
                                                </g>
                                                <g id="alfa_bank">
                                                    <path
                                                        className="st1"
                                                        d="M35.2 120.7c-.7 0-1.3.6-1.3 1.3v14.6c0 .7.6 1.3 1.3 1.3h80c.7 0 1.3-.6 1.3-1.3V122c0-.7-.6-1.3-1.3-1.3h-80M113.5 88.6C112.8 86.7 90 24.4 89 21.7c-2.2-6.2-5-12.5-13.8-12.5s-11.6 6.4-13.8 12.5C60.3 24.9 37.9 86.2 37 88.6c-.2.7.1 1.4.7 1.7L51 95.4c.7.2 1.4-.1 1.6-.8.2-.6 5.2-14.2 5.7-15.7H92c.6 1.5 5.5 15.1 5.7 15.7.2.7 1 1 1.6.8.7-.2 12.5-4.8 13.3-5.1.8-.3 1.1-1.1.9-1.7zM63.8 63.8l11.4-34.2h.1l11.4 34.2H63.8z"
                                                    />
                                                </g>
                                            </svg>
                      <img src="/images/tbank.png" alt="" />
                                        </div>
                                        <p>Карта</p>
                                    </div>
                                    <div
                                        onClick={(e) => {
                                            e.target.parentElement
                                                .querySelector(".selected")
                                                .classList.remove("selected");
                                            e.target.classList.add("selected");
                                            setDepMethod("sbp");
                                        }}
                                    >
                                        <div>
                      <svg width="22" height="22" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m4 8.707 4.844 8.658v5.282l-4.838 8.641z" fill="#5B57A2"/><path d="m22.6 14.215 4.538-2.783 9.29-.008-13.829 8.471z" fill="#D90751"/><path d="m22.573 8.656.026 11.463-4.855-2.983V0z" fill="#FAB718"/><path d="m36.427 11.424-9.29.008-4.564-2.776L17.743 0z" fill="#ED6F26"/><path d="M22.599 31.336v-5.562l-4.855-2.926L17.746 40z" fill="#63B22F"/><path d="M27.127 28.58 8.844 17.364 4 8.707l32.408 19.861z" fill="#1487C9"/><path d="m17.747 40 4.852-8.665 4.527-2.756 9.281-.012z" fill="#017F36"/><path d="m4.006 31.288 13.778-8.44-4.632-2.842-4.308 2.64z" fill="#984995"/></svg>
                                        </div>
                                        <p>СБП</p>
                                    </div>
                  <div
                    onClick={(e) => {
                      e.target.parentElement
                        .querySelector(".selected")
                        .classList.remove("selected");
                      e.target.classList.add("selected");
                      setDepMethod("crypto");
                    }}
                  >
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2500" height="2500" viewBox="0.004 0 63.993 64"><path d="M63.04 39.741c-4.274 17.143-21.638 27.575-38.783 23.301C7.12 58.768-3.313 41.404.962 24.262 5.234 7.117 22.597-3.317 39.737.957c17.144 4.274 27.576 21.64 23.302 38.784z" fill="#f7931a"/><path d="M46.11 27.441c.636-4.258-2.606-6.547-7.039-8.074l1.438-5.768-3.512-.875-1.4 5.616c-.922-.23-1.87-.447-2.812-.662l1.41-5.653-3.509-.875-1.439 5.766c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.209-.934 3.75s2.605.597 2.55.634c1.422.355 1.68 1.296 1.636 2.042l-1.638 6.571c.098.025.225.061.365.117l-.37-.092-2.297 9.205c-.174.432-.615 1.08-1.609.834.035.051-2.552-.637-2.552-.637l-1.743 4.02 4.57 1.139c.85.213 1.683.436 2.502.646l-1.453 5.835 3.507.875 1.44-5.772c.957.26 1.887.5 2.797.726L27.504 50.8l3.511.875 1.453-5.823c5.987 1.133 10.49.676 12.383-4.738 1.527-4.36-.075-6.875-3.225-8.516 2.294-.531 4.022-2.04 4.483-5.157zM38.087 38.69c-1.086 4.36-8.426 2.004-10.807 1.412l1.928-7.729c2.38.594 10.011 1.77 8.88 6.317zm1.085-11.312c-.99 3.966-7.1 1.951-9.083 1.457l1.748-7.01c1.983.494 8.367 1.416 7.335 5.553z" fill="#fff"/></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2500" height="2500" viewBox="0.847 0.876 329.254 329.256"><title>Litecoin</title><path d="M330.102 165.503c0 90.922-73.705 164.629-164.626 164.629C74.554 330.132.848 256.425.848 165.503.848 74.582 74.554.876 165.476.876c90.92 0 164.626 73.706 164.626 164.627" fill="#bebebe"/><path d="M295.15 165.505c0 71.613-58.057 129.675-129.674 129.675-71.616 0-129.677-58.062-129.677-129.675 0-71.619 58.061-129.677 129.677-129.677 71.618 0 129.674 58.057 129.674 129.677" fill="#bebebe"/><path d="M155.854 209.482l10.693-40.264 25.316-9.249 6.297-23.663-.215-.587-24.92 9.104 17.955-67.608h-50.921l-23.481 88.23-19.605 7.162-6.478 24.395 19.59-7.156-13.839 51.998h135.521l8.688-32.362h-84.601" fill="#fff"/></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="2500" height="2500"><path d="M1000 0c552.26 0 1000 447.74 1000 1000s-447.76 1000-1000 1000S0 1552.38 0 1000 447.68 0 1000 0" fill="#53ae94"/><path d="M1123.42 866.76V718h340.18V491.34H537.28V718H877.5v148.64C601 879.34 393.1 934.1 393.1 999.7s208 120.36 484.4 133.14v476.5h246V1132.8c276-12.74 483.48-67.46 483.48-133s-207.48-120.26-483.48-133m0 225.64v-.12c-6.94.44-42.6 2.58-122 2.58-63.48 0-108.14-1.8-123.88-2.62v.2C633.34 1081.66 451 1039.12 451 988.22S633.36 894.84 877.62 884v166.1c16 1.1 61.76 3.8 124.92 3.8 75.86 0 114-3.16 121-3.8V884c243.8 10.86 425.72 53.44 425.72 104.16s-182 93.32-425.72 104.18" fill="#fff"/></svg>
                    </div>
                    <p>КРИПТА</p>
                  </div>
                 
                                </div>
                                {depMethod != "crypto" && (
                                    <>
                                        {" "}
                                        <p>Имя отправителя</p>
                                        <input
                                            value={senderName}
                                            onChange={(e) => {
                                                setSenderName(e.target.value);
                                            }}
                                            type="text"
                                            placeholder="Имя отправителя"
                                        />
                                    </>
                                )}
                                <p>
                                    {t("amount")}, {selectedCurrency}
                                </p>
                                <input
                                    value={depositSum}
                                    onChange={(e) => {
                                        setDepositSum(e.target.value);
                                    }}
                                    type="number"
                                    placeholder="Сумма"
                                />

                                {false && (
                                    <>
                                        {" "}
                                        <div
                                            onClick={(e) => {
                                                if (
                                                    e.target.classList.contains(
                                                        "deposit-bonus-select",
                                                    )
                                                )
                                                    return;
                                                e.target.parentElement
                                                    .querySelector(".selected")
                                                    .classList.remove(
                                                        "selected",
                                                    );
                                                e.target.classList.add(
                                                    "selected",
                                                );

                                                var index = 1;
                                                var children =
                                                        e.target.parentNode
                                                            .childNodes,
                                                    i = 0;
                                                for (
                                                    ;
                                                    i < children.length;
                                                    i++
                                                ) {
                                                    if (
                                                        children[i] == e.target
                                                    ) {
                                                        index = i;
                                                    }
                                                }

                                                setSelectedBonus(index);
                                            }}
                                            className="deposit-bonus-select"
                                        >
                                            <div className="selected">
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12 6v16m0-16H8.464c-.52 0-1.02-.21-1.389-.586A2.02 2.02 0 0 1 6.5 4c0-.53.207-1.04.575-1.414A1.95 1.95 0 0 1 8.465 2C11.214 2 12 6 12 6m0 0h3.536c.52 0 1.02-.21 1.389-.586.368-.375.575-.884.575-1.414s-.207-1.04-.575-1.414A1.95 1.95 0 0 0 15.535 2C12.786 2 12 6 12 6m8 5v7.8c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C18.48 22 17.92 22 16.8 22H7.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C4 20.48 4 19.92 4 18.8V11M2 7.6v1.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C2.76 11 3.04 11 3.6 11h16.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C22 10.24 22 9.96 22 9.4V7.6c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C21.24 6 20.96 6 20.4 6H3.6c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C2 6.76 2 7.04 2 7.6"
                                                        stroke="#4D35AF"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12 6v16m0-16H8.464c-.52 0-1.02-.21-1.389-.586A2.02 2.02 0 0 1 6.5 4c0-.53.207-1.04.575-1.414A1.95 1.95 0 0 1 8.465 2C11.214 2 12 6 12 6m0 0h3.536c.52 0 1.02-.21 1.389-.586.368-.375.575-.884.575-1.414s-.207-1.04-.575-1.414A1.95 1.95 0 0 0 15.535 2C12.786 2 12 6 12 6m8 5v7.8c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C18.48 22 17.92 22 16.8 22H7.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C4 20.48 4 19.92 4 18.8V11M2 7.6v1.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C2.76 11 3.04 11 3.6 11h16.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C22 10.24 22 9.96 22 9.4V7.6c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C21.24 6 20.96 6 20.4 6H3.6c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C2 6.76 2 7.04 2 7.6"
                                                        stroke="#4D35AF"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M18 6 6 18M6 6l12 12"
                                                        stroke="#DF7373"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        <span>
                                            {selectedBonus != bonuses.length
                                                ? bonuses[selectedBonus].name
                                                : "Без бонуса"}
                                        </span>
                                    </>
                                )}
                                <a
                                    onClick={() => {
                                        if (auth) {
                                            if (depositSum < 1000) {
                                                toast.error(
                                                    "Минимальная сумма пополнения - 1000 руб",
                                                );
                                                return;
                                            }
                                            if (
                                                senderName.length < 2 &&
                                                depMethod != "crypto"
                                            ) {
                                                toast.error(
                                                    "Введите имя отправителя!",
                                                );
                                                return;
                                            }
                                            if (depMethod === "crypto") {
                                                axios
                                                    .post(
                                                        process.env
                                                            .REACT_APP_API +
                                                            "/payment/crypto",
                                                        {
                                                            method: "crypto",
                                                            amount: Number(
                                                                depositSum,
                                                            ),
                                                        },
                                                        {
                                                            headers: {
                                                                Authorization:
                                                                    "Bearer " +
                                                                    localStorage.getItem(
                                                                        "accessToken",
                                                                    ),
                                                            },
                                                        },
                                                    )
                                                    .then((response) => {
                                                        if (
                                                            response.data
                                                                ?.cause ===
                                                            "banned"
                                                        ) {
                                                            toast.error(
                                                                "Your account is banned",
                                                            );
                                                        }

                                                        if (
                                                            response.data
                                                                ?.cause ===
                                                            "timeout"
                                                        ) {
                                                            toast.error(
                                                                "Your have 5 minute timeout",
                                                            );
                                                        }

                                                        if (
                                                            response.data
                                                                .invoice
                                                                .status ===
                                                            "success"
                                                        ) {
                                                            if (
                                                                response.data
                                                                    .invoice
                                                                    .result
                                                                    .link !=
                                                                null
                                                            ) {
                                                              if(window.Telegram.WebApp) {
                                                                window.Telegram.WebApp.openLink(response.data.invoice.result.link);
                                                              } else {
                                                                window.open(response.data.invoice.result.link);
                                                              }
                                                            }
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        if (
                                                            error.response.data
                                                                .message ===
                                                            "Banned"
                                                        ) {
                                                            toast.error(
                                                                "Your account is banned",
                                                            );
                                                        }

                                                        if (
                                                            error.response.data
                                                                ?.message ===
                                                            "Timeout 300 seconds"
                                                        ) {
                                                            toast.error(
                                                                "Your have 5 minute timeout",
                                                            );
                                                        }
                                                    });
                                            } else {
                                                var method = depMethod;
                                                console.log(
                                                    process.env.REACT_APP_API +
                                                        "/payment/details?method=" +
                                                        method,
                                                );
                                                axios
                                                    .get(
                                                        process.env
                                                            .REACT_APP_API +
                                                            "/payment/details?method=" +
                                                            method,

                                                        {
                                                            headers: {
                                                                Authorization:
                                                                    "Bearer " +
                                                                    localStorage.getItem(
                                                                        "accessToken",
                                                                    ),
                                                            },
                                                        },
                                                    )
                                                    .then((responseFirst) => {
                                                        console.log({
                                                            sender_name:
                                                                senderName,
                                                            amount: Number(
                                                                depositSum,
                                                            ),
                                                            payment_method:
                                                                depMethod,
                                                        });
                                                        axios
                                                            .post(
                                process.env.REACT_APP_API + "/payment/bank",
                                                                {
                                                                    sender_name:
                                                                        senderName,
                                                                    amount: Number(
                                                                        depositSum,
                                                                    ),
                                                                    payment_method:
                                                                        depMethod,
                                                                },
                                                                {
                                                                    headers: {
                                                                        Authorization:
                                                                            "Bearer " +
                                                                            localStorage.getItem(
                                                                                "accessToken",
                                                                            ),
                                                                    },
                                                                },
                                                            )
                                                            .then(
                                                                (response) => {
                                                                    console.log(
                                                                        response.data,
                                                                    );
                                                                    var detalis =
                                                                        response
                                                                            ?.data
                                                                            ?.invoice
                                                                            ?.payment_details;
                                                                    console.log(
                                                                        detalis,
                                                                    );
                                                                    if (
                                                                        detalis !=
                                                                        null
                                                                    ) {
                                                                        var credentials =
                                                                            null;
                                                                        var isSbp = false;
                                                                        if (
                                                                            method ===
                                                                            "sbp"
                                                                        ) {
                                                                            credentials =
                                                                                detalis.data;
                                                                            isSbp = true;
                                                                        } else {
                                                                            credentials =
                                                                                detalis.data;
                                                                        }

                                                                        var payDataNew =
                                                                            {
                                                                                open: true,
                                                                                credentials:
                                                                                    credentials,
                                                                                bank: detalis.bank,
                                                                                amount: response
                                                                                    .data
                                                                                    .invoice
                                                                                    .amount,
                                                                                id: response
                                                                                    .data
                                                                                    .transaction
                                                                                    .id,
                                                                                isSbp: isSbp,
                                                                                recipient_name:
                                                                                    detalis.recipient_name,
                                                                            };
                                                                        console.log(
                                                                            payDataNew,
                                                                        );
                                                                        setPayDataTime(
                                                                            600,
                                                                        );
                                                                        setPayData(
                                                                            payDataNew,
                                                                        );
                                                                    }


                                                                    if (
                                                                        response
                                                                            .data
                                                                            .cause ===
                                                                        "banned"
                                                                    ) {
                                                                        toast.error(
                                                                            "Your account is banned",
                                                                        );
                                                                    }

                                                                    if (
                                                                        response
                                                                            .data
                                                                            ?.cause ===
                                                                        "timeout"
                                                                    ) {
                                                                        toast.error(
                                                                            "Your have 5 minute timeout",
                                                                        );
                                                                    }
                                                                },
                                                            )
                                                            .catch((error) => {
                                                                if (
                                                                    error.response.data
                                                                        .message ===
                                                                    "Banned"
                                                                ) {
                                                                    toast.error(
                                                                        "Your account is banned",
                                                                    );
                                                                }
    
                                                                if (
                                                                    error.response.data
                                                                        ?.message ===
                                                                    "Timeout 300 seconds"
                                                                ) {
                                                                    toast.error(
                                                                        "Your have 5 minute timeout",
                                                                    );
                                                                }
                                                            });
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });
                                            }
                                        }
                                    }}
                                    className="btn"
                                >
                                    {t("pay")}
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}